<template>

  <!--          Үзсэн түүх-->
  <div class="w-full sm:w-1/1 md:w-3/4 lg:w-3/4 px-2 mb-3 flex flex-col">
    <div class="p-6 bg-white border border-gray-200 rounded-lg w-full shadow-2xl bg-base-100 lg:text-left">
      <header class="rounded-t-lg font-bold text-1xl pb-4 px-6 border-b-[1px] border-gray-500">
        <i class="uil uil-history"></i>
        Үзсэн түүх
      </header>

      <div class="m-2 mx-4">
        <a href="#"
           class="card rounded sm:card-side my-3 sm:flex hover:scale-[102%] hover:bg-[#333d4a] transition-all duration-300 group relative">
          <img src="https://wemax.tv/uploaded/images/2021/Nov/kimera_0000_wide%20(Phone).png"
               class="w-full sm:w-1/3">
          <div class="card-body p-4 sm:py-3 w-full sm:w-2/3">
            <h2 class="card-title text-sm mb-0">Horizontal</h2>
            <h2 class="text-accent text-sm">2020-05-18 00:54:58</h2>
            <p class="text-sm text-base-content text-opacity-60 line-clamp-3">
              Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
              sit amet, consectetur adip!Lorem ipsum dolor sit amet, consectetur
              adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
              dolor sit amet, consectetur adip!Lorem ipsum dolor sit amet,
              consectetur adip!
            </p>
          </div>
          <div class="flex flex-row-reverse flex-col p-3 absolute sm:relative right-0">
            <button
                class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
              <i class="uil-share-alt"></i>
            </button>
            <button
                class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mt-1 hover:bg-[#909399] hover:border-transparent">
              13+
            </button>
          </div>
          <button
              class="link btn-xs rounded text-xs px-1 hover:border-transparent absolute h-14 w-14 -right-4 -bottom-2">
          </button>
          <div
              class="text-[#778289] hover:text-error text-[#A72A39] absolute h-14 w-14 -right-6 -bottom-6 invisible group-hover:visible">
            <i class="uil uil-multiply text-2xl"></i>
          </div>
        </a>

        <a href="#"
           class="card rounded sm:card-side my-3 sm:flex hover:scale-[102%] hover:bg-[#333d4a] transition-all duration-300 group relative">
          <img src="https://wemax.tv/uploaded/images/2021/Nov/kimera_0000_wide%20(Phone).png"
               class="w-full sm:w-1/3">
          <div class="card-body p-4 sm:py-3 w-full sm:w-2/3">
            <h2 class="card-title text-sm mb-0">Horizontal</h2>
            <h2 class="text-accent text-sm">2020-05-18 00:54:58</h2>
            <p class="text-sm text-base-content text-opacity-60 line-clamp-3">
              Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
              sit amet, consectetur adip!Lorem ipsum dolor sit amet, consectetur
              adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
              dolor sit amet, consectetur adip!Lorem ipsum dolor sit amet,
              consectetur adip!
            </p>
          </div>
          <div class="flex flex-row-reverse flex-col p-3 absolute sm:relative right-0">
            <button
                class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
              <i class="uil-share-alt"></i>
            </button>
            <button
                class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mt-1 hover:bg-[#909399] hover:border-transparent">
              13+
            </button>
          </div>
          <button
              class="link btn-xs rounded text-xs px-1 hover:border-transparent absolute h-14 w-14 -right-4 -bottom-2">
          </button>
          <div
              class="text-[#778289] hover:text-error text-[#A72A39] absolute h-14 w-14 -right-6 -bottom-6 invisible group-hover:visible">
            <i class="uil uil-multiply text-2xl"></i>
          </div>
        </a>

        <a href="#"
           class="card rounded sm:card-side my-3 sm:flex hover:scale-[102%] hover:bg-[#333d4a] transition-all duration-300 group relative">
          <img src="https://wemax.tv/uploaded/images/2021/Nov/kimera_0000_wide%20(Phone).png"
               class="w-full sm:w-1/3">
          <div class="card-body p-4 sm:py-3 w-full sm:w-2/3">
            <h2 class="card-title text-sm mb-0">Horizontal</h2>
            <h2 class="text-accent text-sm">2020-05-18 00:54:58</h2>
            <p class="text-sm text-base-content text-opacity-60 line-clamp-3">
              Lorem ipsum dolor sit amet, consectetur adip! Lorem ipsum dolor
              sit amet, consectetur adip!Lorem ipsum dolor sit amet, consectetur
              adip!Lorem ipsum dolor sit amet, consectetur adip!Lorem ipsum
              dolor sit amet, consectetur adip!Lorem ipsum dolor sit amet,
              consectetur adip!
            </p>
          </div>
          <div class="flex flex-row-reverse flex-col p-3 absolute sm:relative right-0">
            <button
                class="btn btn-xs border-transparent bg-accent rounded text-xs px-1 hover:bg-accent-focus hover:border-transparent">
              <i class="uil-share-alt"></i>
            </button>
            <button
                class="btn btn-xs border-transparent bg-[#909399] rounded text-xs px-1 mt-1 hover:bg-[#909399] hover:border-transparent">
              13+
            </button>
          </div>
          <button
              class="link btn-xs rounded text-xs px-1 hover:border-transparent absolute h-14 w-14 -right-4 -bottom-2">
          </button>
          <div
              class="text-[#778289] hover:text-error text-[#A72A39] absolute h-14 w-14 -right-6 -bottom-6 invisible group-hover:visible">
            <i class="uil uil-multiply text-2xl"></i>
          </div>
        </a>
      </div>

    </div>
  </div>

  <!--    <div class="profile-div">-->
  <!--        <div class="profile-card">-->
  <!--            <div class="normal-header">-->
  <!--                <span class="user-name"><i class="el-icon-collection"></i><span class="title-left">Үзсэн түүх</span></span>-->
  <!--            </div>-->
  <!--            <div class="card-content">-->

  <!--                <div v-if="isLoading">-->
  <!--                    <viewed-history-placeholder></viewed-history-placeholder>-->
  <!--                </div>-->
  <!--                <div v-else class="pro-container">-->
  <!--                    <ul>-->
  <!--                        <h3>Yesterday</h3>-->
  <!--                        <li v-for="item in episodes" :key="item.index">-->
  <!--                            <a  :href="`/#/watch/series/episode/`+item.id" class="profile-list-main">-->
  <!--                                <img-->
  <!--                                    src-placeholder="/assets/max/images/bg-loading.gif"-->
  <!--                                    src="https://animax.mn/assets/max/images/poster-horz.png"-->
  <!--                                    v-lazy:background-image="$helper.fixImagePath(item.image1)"-->
  <!--                                    class="poster"-->
  <!--                                />-->
  <!--                                <div class="viewed-video-info">-->
  <!--                                    <div class="viewed-video-title">{{ item.title }}</div>-->
  <!--                                    <div class="viewed-video-sub">{{ item.created_at }}</div>-->
  <!--                                    <div class="viewed-video-desc" v-html="item.description"></div>-->
  <!--                                </div>-->
  <!--                                <div class="viewed-video-button">-->
  <!--                                    <div class="viewed-video-social">-->
  <!--                                        <el-button size="mini" class="x-mini" type="primary" icon="el-icon-share"></el-button>-->
  <!--                                        <el-tag size="mini" effect="dark" type="info" v-show="item.age_limit!= null">{{ item.age_limit }}</el-tag>-->
  <!--                                    </div>-->
  <!--                                    <div class="viewed-video-del">-->
  <!--                                        <el-button @click="toggle()" class="x-mini-trush" icon="el-icon-delete"></el-button>-->
  <!--                                    </div>-->
  <!--                                </div>-->
  <!--                            </a>-->
  <!--                        </li>-->
  <!--                    </ul>-->
  <!--                    <div class="load-more-wrap has-text-centered">-->
  <!--                        <loader v-if="isLoadingMore"></loader>-->
  <!--                        <a-->
  <!--                            v-else-->
  <!--                            href="javascript:void(0)"-->
  <!--                            @click="getEpisodes(page, true)"-->
  <!--                            class="load-more-button"-->
  <!--                        >Цааш үзэх</a-->
  <!--                        >-->
  <!--                    </div>-->
  <!--                </div>-->

  <!--            </div>-->
  <!--        </div>-->
  <!--    </div>-->
</template>

<script>

// import Loader from "../../components/loader.vue";
// import ViewedHistoryPlaceholder from "../../components/placeholders/viewed-history-placeholder.vue";
// import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  name: 'ViewedHistory',
  props: ["url", "hlink"],
  components: {
    // Loader,
    // ViewedHistoryPlaceholder,
  },
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      page: 1,
      episodes: [],
      toggle: true,
    };
  },

  computed: {},

  created() {
    // this.getEpisodes(1, false);
  },

  methods: {
    // ...mapActions(["setEpisode", "gotoTheatre", "getEpisodes", "getAnime", "setBackLink"]),

    toggleItem() {
      this.toggle = !this.toggle;
    },

    getEpisodes(page, isMore) {
      if (this.page == 1) {
        this.isLoading = true;
      }
      if (isMore) {
        this.isLoadingMore = true;
      }

      axios
          // .get(`${this.$props.url}?page=${page}`)
          .get(`https://max.test/api/m/anime/list/2??page=${page}`)
          .then(({data}) => {
            data.data.forEach((item) => {
              this.episodes.push(item);
            });
            this.page = data.current_page + 1;
            this.isLoading = false;
            this.isLoadingMore = false;
            console.log(this.episodes);
          })
          .catch((e) => {
            console.log(e);
            this.isLoading = false;
            this.isLoadingMore = false;
          });
    },

    gotoWatch(id) {
      this.setBackLink(this.$route.path);
      this.$router.push(`/watch/episode/${id}`);
    }
  },
};
</script>
